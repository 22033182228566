import React from "react"
import { GoogleTagManager, sendGTMEvent } from "./next-gtm"
import { parseCookie } from "next/dist/compiled/@edge-runtime/cookies"

export const GtmAnalytics = () => {
  if (!process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID) return null

  return <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID!} />
}

export const sendGtmEvent = (data: { event: Gtag.EventNames; ecommerce?: Gtag.EventParams } | Object) => {
  sendGTMEvent(data)
}

export const getGAClientId = () => {
  return parseCookie(document.cookie).get("_ga")?.substring(6)
}

export const getGAMeasurementId = () => {
  const script = [...document.getElementsByTagName("script")].find((it) =>
    it.src.startsWith("https://www.googletagmanager.com/gtag/destination"),
  )
  if (!script) return

  const [path, query] = script.src.split("?")
  const searchParams = new URLSearchParams(query)
  return searchParams.get("id") || undefined
}
