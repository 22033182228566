"use client"

import React, { lazy, Suspense } from "react"
import { useClient } from "shared/lib"
import type { IFavoriteButtonConnected } from "./favorite-button-connected"
import { FavoriteButton as FavoriteButtonUI } from "./favorite-button"

const FavoriteButtonDynamic = lazy(() => import("./favorite-button-connected"))

export const FavoriteButton = (props: IFavoriteButtonConnected) => {
  if (!useClient()) return <FavoriteButtonUI {...props} />

  return (
    <Suspense fallback={<FavoriteButtonUI {...props} />}>
      <FavoriteButtonDynamic {...props} />
    </Suspense>
  )
}
