import React, { CSSProperties } from "react"
import cx from "classnames"
import styles from "./product-card-split.module.scss"

export interface IProductCardSplit {
  value: number
  className?: string
  style?: CSSProperties
  htmlId?: string
}

export const ProductCardSplit = (props: IProductCardSplit) => {
  return (
    <div className={cx(styles.product_card_split, props.className)} style={props.style} id={props.htmlId}>
      <span className={styles.price}>
        {props.value.toLocaleString("ru", {}).toString().replace(/ /g, "\u2009")}&thinsp;<span>₽</span>
        &thinsp;&times;&thinsp;2
      </span>{" "}
      в сплит
    </div>
  )
}
