import type { ImageLoaderProps, ImageProps } from "next/image"

export const poizonImageLoader = (props: ImageLoaderProps) => {
  return `https://image.unicorngo.ru/_next/image?url=${props.src}&w=${props.width}&q=${props.quality || 75}`
}

export const poizonImageLoaderTrim = (props: ImageLoaderProps) => {
  return `https://img-cdn.unicorngo.ru/image?url=${props.src}&width=${props.width}&trim=1`
}

export const poizonImageLoaderPropsIfNeed = (src: string, trim = false): { loader?: ImageProps["loader"] } => {
  if (src.startsWith("https://cdn.poizon.com")) {
    return { loader: trim ? poizonImageLoaderTrim : poizonImageLoader }
  }

  return {}
}
