"use client"

import React, { CSSProperties, useCallback } from "react"
import cx from "classnames"
import styles from "./favorite-button.module.scss"
import { IconHeart } from "@tabler/icons-react"

export interface IFavoriteButton {
  active?: boolean

  size?: number
  stroke?: number

  onClick?: () => void

  children?: React.ReactNode
  className?: string
  style?: CSSProperties
  htmlId?: string
}

export const FavoriteButton = (props: IFavoriteButton) => {
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()

      props.onClick?.()
    },
    [props.onClick],
  )

  return (
    <button
      className={cx(styles.favorite_button, props.active && styles.on, props.className)}
      style={props.style}
      id={props.htmlId}
      onClick={onClick}
      aria-label={props.active ? "Лайк" : "Снять лайк"}
    >
      <IconHeart size={props.size || 22} stroke={props.stroke || 1} />
    </button>
  )
}
