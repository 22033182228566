import React, { CSSProperties } from "react"
import cx from "classnames"
import styles from "./product-card-price.module.scss"
import type { CatalogProductPriceAvailability } from "shared/api"
import { numberWithSpaces } from "shared/lib/number-with-spaces"

export interface IProductCardPrice {
  availability: CatalogProductPriceAvailability
  price: number
  priceWithoutDiscount?: number
  sale?: boolean
  className?: string
  style?: CSSProperties
  htmlId?: string
}

export const ProductCardPrice = (props: IProductCardPrice) => {
  return (
    <div
      className={cx(
        styles.product_card_price,
        props.availability === "AVAILABLE" && styles.num,
        props?.priceWithoutDiscount && props.price < props.priceWithoutDiscount && props.sale && styles.sale,
        props.className,
      )}
      style={props.style}
      id={props.htmlId}
    >
      {props.availability === "AVAILABLE" && (
        <>
          {numberWithSpaces(props.price)}&nbsp;<span>₽</span>{" "}
          {!!props.priceWithoutDiscount &&
            !!props.price &&
            props.price !== props.priceWithoutDiscount &&
            props.price < props.priceWithoutDiscount && (
              <span className={styles.price_without_discount}>{numberWithSpaces(props.priceWithoutDiscount)}</span>
            )}
        </>
      )}
      {props.availability === "NOT_AVAILABLE" && "Нет в наличии"}
      {props.availability === "UNKNOWN" && "Цена по запросу"}
    </div>
  )
}
