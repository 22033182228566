"use client"

import React, { CSSProperties, Suspense, useCallback, useMemo } from "react"
import styles from "./product-card.module.scss"
import cx from "classnames"
import type { CatalogProductShortDto } from "shared/api"
import type { LinkProps } from "next/link"
import Link from "next/link"
import { ProductCardImages as Images } from "./images"
import { ProductCardPrice as Price } from "./price"
import { IconBolt } from "@tabler/icons-react"
import { ProductCardSplit as Spit } from "./split"
import { productToItem } from "entity/analytics/converters/product-to-item"
import { selectItem } from "entity/analytics/events/select-item"
import { FavoriteButton } from "entity/favorite"
import { defaultDeliveryTime } from "features/product-delivery-time-selector/default-delivery-time"
import { pluralize } from "shared/lib"

interface IProductCard {
  data: CatalogProductShortDto
  targetBlank?: boolean
  list?: { id: string; name: string }

  previewImagesCount?: number

  query?: {
    collectionSlug?: string
  }
  className?: string
  style?: CSSProperties
  htmlId?: string
  linkProps?: Omit<LinkProps, "href">
  prefetch?: boolean
}

export const ProductCard = (props: IProductCard) => {
  const images = useMemo(
    () => (props.previewImagesCount ? props.data.images?.slice(0, props.previewImagesCount) : props.data.images),
    [props.previewImagesCount, props.data.images],
  )

  const data = props.data

  const discount = { global: 0, personal: 0, total: 0 }

  const poizonDiscount = data.maxPrice > data.price

  const price = data.price - (discount.personal || 0)
  const priceWithoutDiscount = data.maxPrice + discount.global

  // const ref = useRef<HTMLAnchorElement>(null)
  //
  // const visible = useInView(ref, { amount: 0.8, once: true })
  //
  // useEffect(() => {
  //   if (visible) {
  //     viewItemList({ items: [productToItem(data)] })
  //   }
  // }, [visible])

  const onClick = useCallback(() => {
    if (props.list) {
      selectItem({ item_list_id: props.list.id, item_list_name: props.list.name, items: [productToItem(data)] })
    }
  }, [data, props.list?.id, props.list?.name])

  let href = `/product/${data.slug}-${data.spuId}?sku=${data.skuId}`
  if (props.query?.collectionSlug) {
    href += `&collectionSlug=${props.query?.collectionSlug}`
  }

  const deliveryTime = props.data.deliveryTime || defaultDeliveryTime

  return (
    <Link
      href={href}
      target={props.targetBlank ? "_blank" : undefined}
      className={cx(styles.product_card, props.className)}
      style={props.style}
      id={props.htmlId}
      prefetch={false}
      onClick={onClick}
    >
      <Images images={images} alt={data.name} />
      <Suspense>
        <FavoriteButton product={data} className={styles.favorite} />
      </Suspense>

      <div className={styles.card_body}>
        <Price
          sale={poizonDiscount}
          availability={data.availability}
          price={price}
          priceWithoutDiscount={priceWithoutDiscount}
        />
        {data.split && <Spit value={data.split.first} />}

        <div className={styles.name}>{data.name}</div>

        <div className={styles.delivery}>
          {props.data.fromAvailability ? (
            <>
              <span className={styles.now}>сегодня</span>
            </>
          ) : (
            <>
              {pluralize(deliveryTime.min, {
                one: (it) => `${it} день`,
                few: (it) => `${it} дня`,
                many: (it) => `${it} дней`,
              })}{" "}
              &middot; <IconBolt stroke={1} size={14} className={styles.icon} />
              {pluralize(deliveryTime.expressMin, {
                one: (it) => `${it} день`,
                few: (it) => `${it} дня`,
                many: (it) => `${it} дней`,
              })}
            </>
          )}
        </div>
      </div>
    </Link>
  )
}
